/**
 * Default map layer - contructor create geometry layer
 */
import {addFlash} from "./base";

export class MapLayer {

    /**
     * layer name
     * @type {string}
     */
    layer_name = 'default';

    /**
     * @type {Map}
     */
    parent_map = new Map();

    /**
     * @type {SMap}
     */
    m = null;

    /**
     * layer for adding features
     * @type {SMap.Layer}
     */
    layer = null;

    /**
     * Layer object can be instance
     * @param Map parent_map
     * @param string layer_name
     */
    constructor(parent_map, layer_name) {
        // setting parent
        this.parent_map = parent_map;
        this.m = parent_map.m;

        this.layer_name = layer_name;
        // init  layer
        this.layer = this.m.addLayer(new SMap.Layer.Geometry(this.layer_name));
        this.listeners = [];
    }

    addFeature(feature) {
        let coords = feature.geometry.coordinates;
        let type = feature.geometry.type;

        // if geometry is Polygon change to MultiPolygon
        if (type != 'MultiPolygon') {
            coords = [coords];
        }

        // use default properties enriched by set from feature
        let properties = this.getFeatureProperties(feature.properties);
        // create data path from MultiPoylgon soords
        let path_data = this.getPathDataFromMultiPolygonCoords(coords);
        // create polygon in geoemtry
        let polygon = new SMap.Geometry(SMap.GEOMETRY_PATH, properties.id, path_data, properties);

        // add card to each geometry
        var card = new SMap.Card(null, {anchor: {left: 0, top: -35}});
        polygon.decorate(SMap.Geometry.Feature.Card, card);
        this.layer.addGeometry(polygon);
    }

    processData(data) {
        var obj = this;
        data.forEach((el) => {
            obj.addFeature(el);
        });
    }

    getFeatureProperties(set) {
        let properties = {
            "color": "red",
            "text_color": null,
            "opacity": 0.8,
            "outlineColor": "red",
            "outlineOpacity": 0.8,
            "outlineWidth": 5,
            "curvature": 0,
            "title": null,
        };

        // set same color for line if not other set
        if (set.color && !set.outlineColor) {
            set.outlineColor = set.color;
        }
        // set same color opacity for line if not other set
        if (set.opacity && !set.outlineOpacity) {
            set.outlineOpacity = set.opacity;
        }

        return Object.assign({}, properties, set ?? {});
    }

    /**
     * Metoda ktera vezme objet z JSON souradnic lat,lng a prevede na souradnice smap a surove souradnice
     * @param coords
     * @returns {{smapCoords: Array, coords: Array}}
     */
    getCoordsArray(coords) {
        let smap_coords = [];
        let polygonGJSON = [];
        for (let ii = 0; ii < coords.length; ii++) {
            let node = coords[ii];
            let lat = parseFloat(node[0]);
            let lon = parseFloat(node[1]);
            let c = SMap.Coords.fromWGS84(lat, lon);
            smap_coords.push(c);                 // vytvorit data pro vypis geometricke vrstvy
            polygonGJSON.push([lat, lon]);       // pripravit data pro vypocet stredu polygonu
        }
        return {"smapCoords": smap_coords, 'coords': polygonGJSON};
    }

    enable() {
        this.layer.enable();
        this.setListeners();
    }

    disable() {
        this.layer.disable();
        this.removeListeners();
        this.m.removeCard();
    }

    setListeners() {
        let obj = this;
        const formPrefix = document.querySelector('.container').dataset.formName || "parking_ticket";
        let ticketPanel = document.querySelector('.ticket-panel');
        let locationTextInput = ticketPanel.querySelector('#' + formPrefix + '_locationText');
        let locationInput = ticketPanel.querySelector('#' + formPrefix + '_location');

        let listenerGeometryClick = function (e) {
            // prevent to miss clicks to elements with ID not conected with geometry
            if (!obj.layer.isActive()) return false;
            if (!obj.layer._geometries[e.target._id]) return false;
            let coords = SMap.Coords.fromEvent(e.data.event, obj.m);
            // action after geometry  click
            let geometry = obj.layer._geometries[e.target._id];

            // create change events
            locationTextInput.value = geometry._options.location_name;
            locationInput.value = geometry._options.location_id;
            // use cancelable to divide from another type of change event in f() fucntion
            locationTextInput.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
            locationInput.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));

            // set postions marker
            obj.parent_map.setPositionMarker(coords, null);
            // show geoemetry card
            obj.showGeometryCard(geometry);
            // because of show input for searching
            //.animate({ scrollTop: 0 }, "slow");
            window.scrollTo({
                top: locationTextInput.offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        };

        // nastavit listenery
        let signals = this.m.getSignals();
        this.listeners.push(signals.addListener(this, "geometry-click", listenerGeometryClick));
    }

    removeListeners() {
        var signals = this.m.getSignals();
        this.listeners.forEach((el) => {
            signals.removeListener(el);
        });
        this.listeners = [];
    }

    /**
     * Vraci cast path dat bez koncoveho symobolu "Z"
     * @param coords
     */
    getPathData(coords) {
        let pathData = [];
        let cnt = coords.length;
        for (let ii = 0; ii < cnt; ii++) {
            pathData.push(((ii == 0) ? "M" : "L"));
            pathData.push(coords[ii]);
        }
        return pathData;
    }

    getPathDataFromMultiPolygonCoords(mp_coords) {
        let path_data = [];
        let polygon_coords = null;
        // iterate multipolygon data
        for (let i = 0; i < mp_coords.length; i++) {
            polygon_coords = this.getCoordsArray(mp_coords[i][0]);
            path_data = path_data.concat(this.getPathData(polygon_coords.smapCoords));
            // if there are multiple coordinates array, it's "holes" or multipolygon so add them to path!
            for (let ii = 1; ii < mp_coords[i].length; ii++) {
                polygon_coords = this.getCoordsArray(mp_coords[i][ii]);
                path_data = path_data.concat(this.getPathData(polygon_coords.smapCoords));
            }
        }
        path_data.push("Z");

        return path_data;
    }

    getCardHeader() {
        return `<i class="fas fa-map-marker-alt"></i>Stojíte v oblasti<div class="area-in" data-title="location_name"></div>`;
    }

    pushAreaSpan(el, geometry) {
        let color = geometry._options.color;
        let title_prop = el.dataset.title ?? 'title';
        let title = geometry._options[title_prop];
        el.innerHTML = `<span class="area" title="${title}" style="background: ${color}">${title}</span>`;
    };


    showGeometryCard(geometry) {
        let lg = geometry._owner;
        let cardBody = geometry._card._dom.body;
        cardBody.innerHTML = this.getCardHeader();
        this.pushAreaSpan(cardBody.querySelector('.area-in'), lg._geometries[geometry._id]);
        lg.redraw();
    }
}

export class Map {
    /**
     * SMap
     */
    m = null;

    /**
     * Layers
     * @type {[]}
     */
    layers = []

    zoom = 11;
    center = false;

    position_coords = null;

    /**
     * Mpa object can be instance
     * @param m
     */
    constructor(m) {
        this.m = m;
    }

    /**
     * initialization of map container
     */
    init(map_container_id, center_coords, zoom = this.zoom) {
        this.zoom = zoom;
        let map_center = SMap.Coords.fromWGS84(center_coords[0], center_coords[1]);
        this.m = new SMap(JAK.gel(map_container_id), map_center, this.zoom);
        this.m.addDefaultLayer(SMap.DEF_BASE).enable();
        this.m.addDefaultControls();
        this.m.setCursor("pointer");

        // create postion layer
        this.layers['position'] = this.m.addLayer(new SMap.Layer.Marker('position'));

    }

    /**
     * add layer and save layer to layers objects
     * @param name
     * @returns MapLayer
     */
    addLayer(name = 'default') {
        let map_layer = new MapLayer(this, name);
        this.layers[name] = map_layer.layer;
        return map_layer;
    }

    setPositionMarker(coords, label = "", center = null) {
        if (center != null) {
            self.center = center;
        }
        // remove old marker 
        this.removePositionMarker();

        let marker = new SMap.Marker(coords, "myPosition", {title: label});

        // show marker in layer
        this.layers['position'].addMarker(marker);
        this.layers['position'].enable();

        // center to position marker default is false
        if (self.center) {
            this.setMapCenter(coords);
        }
    };

    removePositionMarker() {
        this.layers['position'].removeAll();
        this.layers['position'].disable();
        // remove all cards
        this.m.removeCard();
    };

    setGeoPositionMarker() {
        let self = this;
        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({name: 'geolocation'}).then(function (result) {
                const permission = result.state;
                if (permission === 'granted' || permission === 'prompt') {
                    self.onGetCurrentLocation(self);
                }
            });
        } else if (navigator.geolocation) {
            self.onGetCurrentLocation(self);
        }

        return !!self.position_coords;
    }

    onGetCurrentLocation() {
        let self = this;
        navigator.geolocation.getCurrentPosition(function showPosition(position) {
            let coords = SMap.Coords.fromWGS84(position.coords.longitude, position.coords.latitude);
            self.setPositionMarker(coords, "Zde stojíte", true);
            self.position_coords = coords;

            let mapaDiv = document.getElementById('mapa');

            if (mapaDiv) {
                fetch('/order/location?lat=' + position.coords.latitude + '&long=' + position.coords.longitude, {
                    headers: {
                        "X-CSRF-Token": mapaDiv?.dataset.token
                    }
                })
                    .then((res) => {
                        res.json().then((data) => {
                            if (data.id) {
                                let ticketPanel = document.querySelector('.ticket-panel');
                                const formPrefix = document.querySelector('.container').dataset.formName || "parking_ticket";
                                let locationTextInput = ticketPanel.querySelector('#' + formPrefix + '_locationText');
                                let locationInput = ticketPanel.querySelector('#' + formPrefix + '_location');

                                locationTextInput.value = data.name;
                                locationTextInput.dispatchEvent(new Event('change', {bubbles: true}));

                                locationInput.value = data.id;
                                locationInput.dispatchEvent(new Event('change', {bubbles: true}));

                                self.setPositionMarker(coords, "Zde stojíte", true);
                                self.position_coords = coords;
                            }
                        });
                    })
                    .catch((err) => console.error(err));
            }
        });

    }

    setMapCenter(coords, zoom = this.zoom) {
        this.m.setCenterZoom(coords, zoom, true);
    }
}

export default function map_init() {
    let mapaDiv = document.getElementById('mapa');
    if (!mapaDiv) {
        return;
    }
    let locations = {};

    let map_center = [mapaDiv.dataset.cordX, mapaDiv.dataset.cordY];
    let zoom = mapaDiv.dataset.zoom;

    if (typeof SMap === 'undefined') {
        return;
    }
    let map = new Map();
    map.init(mapaDiv.id, map_center, zoom);

    let mapaCardHeaderHandle = document.querySelector('.card-header-handle');
    let ticketPanel = document.querySelector('.ticket-panel');
    const formPrefix = document.querySelector('.container').dataset.formName || "parking_ticket";
    let locationTextInput = ticketPanel.querySelector('#' + formPrefix + '_locationText');
    let locationInput = ticketPanel.querySelector('#' + formPrefix + '_location');

    function toggle(container) {
        if (container.clientHeight === 0) {
            container.classList.add('toggleMe-active');

            setTimeout(() => {
                container.style.height = container.dataset.height + 'px';

                setTimeout(() => {
                    container.style.height = null;
                }, 500);
            }, 100);
        } else {
            container.dataset.height = container.clientHeight;
            container.style.height = container.dataset.height + 'px';

            setTimeout(() => {
                container.style.height = '0px';

                setTimeout(() => {
                    container.classList.remove('toggleMe-active');
                }, 500);
            }, 100);
        }
    }

    // add handler to expand map
    mapaCardHeaderHandle.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        let handleUp = mapaCardHeaderHandle.querySelector('.handle-up');
        if (!handleUp.classList.contains('d-none')) {
            // expand map + hegiht of ticket-panelu
            // ulozit puvodni vysku
            mapaDiv.dataset.height = mapaDiv.clientHeight;

            var h = mapaDiv.clientHeight + ticketPanel.clientHeight;
            mapaDiv.style.height = h + 'px';
        } else {
            // restore map height
            mapaDiv.style.height = mapaDiv.dataset.height + 'px';
        }
        setTimeout(() => {
            map.m.syncPort();
        }, 200);

        toggle(ticketPanel);

        if (handleUp.classList.contains('d-none')) {
            handleUp.classList.remove('d-none');
        } else {
            handleUp.classList.add('d-none');
        }
        let handleDown = mapaCardHeaderHandle.querySelector('.handle-down');
        if (handleDown.classList.contains('d-none')) {
            handleDown.classList.remove('d-none');
        } else {
            handleDown.classList.add('d-none');
        }
        window.scrollTo({
            top: ticketPanel.offsetTop - 10,
            left: 0,
            behavior: 'smooth'
        });
    });

    // handler to center map
    let currentPosition = document.querySelector('#show_current_position');
    if (currentPosition) {
        currentPosition.addEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();

            if (map.setGeoPositionMarker()) {
                // scroll to map
                //$map[0].scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});    // it lose focus and need too doubleclick on modal buttons (bcs of value biger than 500)
                window.scrollTo({
                    top: 300,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                addFlash('warning', 'Povolte použití aktuální polohy.', {autoHide: true});
            }
        });
    }

    // autocomplete with smap suggest
    function f() {
        if (!locationInput) {
            return;
        }

        if (locationInput.value == '') {
            map.removePositionMarker();
        } else {
            if (locations[locationInput.value] !== undefined) {
                let firstFeature = locations[locationInput.value][0];
                let geometry_coords = firstFeature.geometry.coordinates;
                // if geometry is Polygon change to MultiPolygon
                if (firstFeature.geometry.type != 'MultiPolygon') {
                    geometry_coords = [geometry_coords];
                }

                let coords = [];
                geometry_coords[0].forEach(points => {
                    points.forEach(point => {
                        coords.push(SMap.Coords.fromWGS84(point[0], point[1]));
                    });
                });
                let mapCoords = map.m.computeCenterZoom(coords, false);

                let markerCoords = coords[0];
                let markerDistance = mapCoords[0].distance(coords[0], coords[0].getAltitude());
                coords.forEach(point => {
                    let newMarkerDistance = mapCoords[0].distance(point, point.getAltitude());
                    if (newMarkerDistance < markerDistance) {
                        markerDistance = newMarkerDistance;
                        markerCoords = point;
                    }
                });

                map.setPositionMarker(markerCoords, firstFeature.properties.title, false);
                map.setMapCenter(mapCoords[0], mapCoords[1]);
            } else {
                map.removePositionMarker();
            }
        }
    }

    if (locationTextInput) {
        locationInput.addEventListener('change', (event) => {
            if (!event.cancelable) {
                f();
            }
        });
    }

    let mapLayer = map.addLayer();

    map.setGeoPositionMarker();

    let mapLocationsType = mapaDiv?.dataset.type;

    // load data of locations into map
    fetch('/order/locations' + (mapLocationsType ? ('?type=' + mapLocationsType) : ''))
        .then((res) => {
            res.json().then(function (data) {
                data.forEach((feature) => {
                    // adjust data
                    feature.properties.title = feature.properties.location_name;
                    mapLayer.addFeature(feature);

                    if (locations[feature.properties.location_id] === undefined) {
                        locations[feature.properties.location_id] = [];
                    }

                    locations[feature.properties.location_id].push(feature);
                });
                mapLayer.enable();
                f();
            });
        })
        .catch((err) => console.error(err));
}
