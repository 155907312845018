const myModalEl = document.getElementById('modalticket');
if (myModalEl) {
    const qrReader = document.getElementById('qr-reader');
    const qrStatusText = myModalEl.querySelector('#qr-status-text');
    const qrSpinner = myModalEl.querySelector('.spinner-border');
    const camList = document.getElementById('cam-list');
    const modalData = document.getElementById('modalPaymentTitle').dataset;
    const cameraSwitch = document.getElementById('scan-camera-switch');
    let successfullyScanned = false;
    let defaultCameras = []; //JSON.parse(document.getElementById('parking_ticket_search').getAttribute('camera'));//['camera2 0', 'ultra'];   // list of camera names for default selection (use lowercase!)

    let qrScanner = null;
    let QrScannerLib = null;

    async function getQrScanner() {
        if (qrScanner) {
            return qrScanner;
        }

        const qr = await import('qr-scanner');
        QrScannerLib = qr.default;

        qrScanner = new QrScannerLib(qrReader, result => parseQRCode(result), {
            //preferredCamera: 'environment',
            highlightScanRegion: true,
            highlightCodeOutline: true,
            maxScansPerSecond: 10,
            // calculateScanRegion:(v)=>{
            //     const smallestDimension = Math.min(v.videoWidth, v.videoHeight);
            //
            //     // Make scan region smaller to match better small qr codes
            //     const scanRegionSize = Math.round(1 / 4 * smallestDimension);
            //
            //     let region = QrScanner.ScanRegion = {
            //         x: Math.round((v.videoWidth - scanRegionSize) / 2),
            //         y: Math.round((v.videoHeight - scanRegionSize) / 2),
            //         width: scanRegionSize,
            //         height: scanRegionSize,
            //     };
            //     return region;
            // }
        });

        return qrScanner;
    }


    let parsed = {
        date: null,
        code: null
    };

    function parseQRCode(code) {
        const token = modalData.token;

        let currentTime = (new Date()).getTime();
        if (parsed.code === code.data && parsed.date >= (currentTime - 600)) {
            return;
        } else {
            parsed.code = code.data;
            parsed.date = currentTime;
        }

        fetch('/order/qr/parse', {
            method: 'POST',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "X-CSRF-Token": token
            },
            body: 'qr_code_data=' + code.data
        }).then((res) => {
            res.text().then(function (text) {
                let data = null;
                try {
                    data = JSON.parse(text);
                } catch (e) {
                }

                if (data && data.hostname_match) {
                    qrStatusTextUpdate('');
                    qrSpinner.classList.remove('d-none');
                    sendEventAndRedirect(data.url);
                } else {
                    qrStatusTextUpdate('Neplatný QR kód.');
                    setTimeout(function () {
                        qrStatusTextUpdate('');
                    }, 3000);
                }
            });
        })
            .catch((err) => console.error(err));
    }

    function sendEventAndRedirect(data_url) {
        const token = modalData.token;
        const oid = modalData.oid || 0;

        let camera = null;
        if (camList.selectedIndex || camList.value) {
            const option = camList.options[camList.selectedIndex];
            camera = {value: option.value, text: option.text};
        }

        if (!successfullyScanned) {
            successfullyScanned = true;
            let locationId = document.querySelector('#parking_ticket_location').value;

            fetch('/order/ga/' + oid + '/scanned', {
                method: 'POST',
                headers: {
                    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                    "X-CSRF-Token": token
                },
                body: 'data_url=' + data_url + '&location=' + encodeURIComponent(locationId) + '&camera=' + encodeURIComponent(JSON.stringify(camera))
            }).then(() => {
                window.location.href = data_url;
                if (qrScanner) {
                    qrScanner.stop();
                }
                // camList.parentElement.classList.add('d-none');
            })
                .catch((err) => console.error(err));
        }
    }

    function qrStatusTextUpdate(text) {
        qrStatusText.innerHTML = text;
        qrStatusText.style.visibility = text.length > 0 ? 'visible' : 'hidden';
    }

    myModalEl.addEventListener('shown.bs.modal', async event => {
        defaultCameras = JSON.parse(document.getElementById('parking_ticket_config').getAttribute('camera'));

        let qrLib;
        try {
            qrLib = await getQrScanner();
        } catch (e) {
            qrStatusTextUpdate('Nepodařilo se načíst kameru.');
            qrSpinner.classList.add('d-none');

            console.error(e);

            return;
        }
        qrLib.start()
            .then(() => {
                // switch camera
                camList.addEventListener('change', event => {
                    qrScanner.setCamera(event.target.value);
                    qrStatusTextUpdate(camList.options[camList.selectedIndex].text);
                    setTimeout(function () {
                        qrStatusTextUpdate('');
                    }, 1000);
                });
                // process available cameras
                QrScannerLib.listCameras(true).then(cameras => {
                    cameras.forEach(camera => {
                        // add to camera switcher
                        const option = document.createElement('option');
                        option.value = camera.id;
                        option.text = camera.label;
                        camList.add(option);
                    });
                    let selectedCamera = false;
                    for (const dc of defaultCameras) {
                        let cameraName = dc.toLowerCase();
                        for (const camera of cameras) {
                            if (cameraName.includes(camera.label.toLowerCase())) {
                                camList.value = camera.id;
                                camList.dispatchEvent(new Event('change'));
                                selectedCamera = true;
                                break;
                            }
                        }
                        if (selectedCamera) {
                            break;
                        }
                    }
                    // choose default camera (used especialy for selectin macro cameras by label
                    if (!selectedCamera) {
                        camList.value = 'environment';
                        camList.dispatchEvent(new Event('change'));
                    }
                });
                qrSpinner.classList.add('d-none')
            })
            // camList.parentElement.classList.remove('d-none')
            .catch((err) => {
                console.info(err);
                qrStatusTextUpdate('Nepodařilo se načíst kameru. Nejspíš jsme od vás nedostali povolení spustit kameru na této stránce');

                qrSpinner.classList.add('d-none');
            });

    })
    myModalEl.addEventListener('hide.bs.modal', event => {
        if (qrScanner) {
            qrScanner.stop();
        }
    });

// switch camera loop through options
    cameraSwitch.addEventListener('click', event => {
        if (camList.selectedIndex + 1 >= camList.length) {
            camList.selectedIndex = 0;
        } else {
            camList.selectedIndex = camList.selectedIndex + 1;
        }
        camList.dispatchEvent(new Event('change'));
    });
}